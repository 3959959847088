/**
 * 
 * @returns 
 */
export function appUrl() {
  const env = appEnv() || 'development'
  if (env == 'development') {
    return 'http://localhost:3000'
  }

  return window.location.protocol + "//" + window.location.host
}

/**
 * 
 * @returns 
 */
export function appVersion() {
  if (window.$_appVersion) return window.$_appVersion

  const metaAppVersion = document.querySelector('meta[name="app-version"]')
  if (metaAppVersion) window.$_appVersion = metaAppVersion.getAttribute('content')

  return window.$_appVersion
}

/**
 * 
 * @returns 
 */
export function appEnv() {
  if (window.$_appEnv) return window.$_appEnv

  const metaAppEnv = document.querySelector('meta[name="app-env"]')
  if (metaAppEnv) window.$_appEnv = metaAppEnv.getAttribute('content')

  return window.$_appEnv
}

/**
 * 
 * @returns 
 */
export function appMobile() {
  return window.matchMedia('(max-width: 767px)').matches
}

/**
 * 
 * @returns 
 */
 export function appDesktop() {
  return window.matchMedia('(min-width: 768px)').matches
}