import { appVersion, appEnv } from 'libs/appLib'

// Start service worker
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js')
  .then(function(reg) {
    console.log('[Page] Service worker registered!')
  })
}

/**
 * Esecuzione iniziale javascript.
 */
document.addEventListener('DOMContentLoaded', () => {
  console.log(`%cRunning on "${appEnv()}" - v.${appVersion()}`, 'color: #ffffff; font-size: 18px')
})